// components/MyLink.tsx
import NextLink, { LinkProps } from "next/link";
import { AnchorHTMLAttributes, FC, ReactNode } from "react";

interface MyLinkProps
  extends LinkProps,
    AnchorHTMLAttributes<HTMLAnchorElement> {
  children: ReactNode;
  href: string;
}

const MyLink: FC<MyLinkProps> = ({ href, children, ...props }) => {
  return (
    <NextLink href={href} prefetch={false} {...props}>
      {children}
    </NextLink>
  );
};

export default MyLink;
